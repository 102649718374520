import * as React from 'react';
import { useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import * as Error from '@happenings/components/errors';
import { initSession } from '@happenings/components/session';

import axios from 'axios';
import { getFetchParams, sanitizeDisplayName } from '@happenings/components/util';
import { validatePhoneNumber, normalizePhoneNumber, formatPhoneNumber } from '@happenings/components/util/phone';
import Store from '@happenings/components/store';

import { useQuery } from '../util';
import { useHistory } from 'react-router-dom';

import { UpdateButtonBase as UpdateButton } from '../util/UpdateButton';

const mapState = (state: Store) => ({
  loggedIn: !!state.session.currentUser,
  loading: state.ui.loading.updateInProgress
});

const mapDispatch = {
  init: () => initSession(),
};

const connector = connect(mapState, mapDispatch);
type SignUpProps = ConnectedProps<typeof connector>;

type SignupFormData = {
  phone: string;
};

type SubmitVerificationCodeProps = {
  phone: string;
  onAuthSuccess: (username: string) => void;
  onSuccess: (token: string) => void;
};

const SubmitVerificationCode: React.FC<SubmitVerificationCodeProps> = ({ phone, onSuccess, onAuthSuccess }) => {
  type VerifyForm = {
    code: string;
  };

  const [ verificationError, setVerificationError ] = React.useState(false);
  const [ updateInProgress, setUpdateInProgress ] = React.useState(false);

  const { register, handleSubmit, errors } = useForm<VerifyForm>();
  const onSubmit = (data: VerifyForm) => {
    setUpdateInProgress(true);
    const { code } = data;
    axios
      .post('/api/sms/verify-phone-number', { phone, code }, getFetchParams())
      .then((res) => {
        if (res.data.username) {
          onAuthSuccess(res.data.username);
        } else {
          // otherwise, pass the verificationToken onto the next step of the flow
          // this feels very saga-y...
          onSuccess(res.data.verificationToken);
        }
      })
      .catch((e) => {
        console.error(e);
        setVerificationError(true);
      })
      .finally(() => setUpdateInProgress(false));
  };

  return (
    <div className="signup-form">
      <div>Verify your phone number</div>
      <div className="text-fine-print">We sent a six digit code to {formatPhoneNumber(phone)}</div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          autoComplete="one-time-code"
          placeholder="XXXXXX"
          inputMode='numeric'
          maxLength={6}
          name="code"
          ref={register({
            required: true,
            pattern: {
              value: /^[0-9]{6}$/,
              message: 'code must be 6 digits'
            }
          })} />
        {errors.code && <span className="form-error">{errors.code.message}</span>}
        {verificationError && <span className="form-error">{'hmm didn\'t work, try reloading the page and sending a new code'}</span>}
        <div className="text-fine-print">
          <span>Didn&apos;t get a code? <a href="/start">Resend it</a></span>
        </div>
        <UpdateButton text="Verify" handlerFunc={() => {
          setUpdateInProgress(true);
          handleSubmit(onSubmit);
        }}
          updateInProgress={updateInProgress}
        />
      </form>
    </div>
  );
};

type UsernameSelectionProps = {
  verificationToken: string;
  onSuccess: (username: string) => void;
};


const UsernameSelection: React.FC<UsernameSelectionProps> = ({ verificationToken, onSuccess }) => {
  type UsernameForm = {
    username: string;
    displayName: string;
  };
  const [ usernameTaken, setUsernameTaken ] = React.useState(false);
  const [ updateInProgress, setUpdateInProgress ] = React.useState(false);

  const { register, handleSubmit, errors } = useForm<UsernameForm>();
  const onSubmit = (data: UsernameForm) => {
    const { username } = data;
    const displayName = sanitizeDisplayName(data.displayName);
    setUpdateInProgress(true);
    axios
      .post('/api/auth/register-via-phone', { username, displayName, verificationToken }, getFetchParams())
      .then(() => onSuccess(username))
      .catch((e) => {
        const { error } = e.response.data;
        setUsernameTaken(error === Error.API_USERNAME_UNIQUE_MSG);
      })
      .finally(() => setUpdateInProgress(false));
  };

  return (
    <div className="signup-form">
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div>Last step! Pick a username for your account</div>
        <input
          type="text"
          placeholder="username"
          name="username"
          ref={register({
            required: 'username required',
            validate: u => u === u.toLowerCase() || 'username must be lowercase',
          })}
        />
        {errors.username && <span className="form-error">{errors.username.message}</span>}
        {usernameTaken && <span className="form-error">{Error.USERNAME_UNAVAILABLE}</span>}
        <div>and a display name to show on RSVP lists, etc</div>
        <input
          type="text"
          placeholder="display name"
          name="displayName"
          ref={register({
            required: 'please enter a display name',
            validate: {
              // alphanumeric with spaces
              alphanumeric: v => /^[a-zA-Z0-9 ]*$/.test(v) || 'must be alphanumeric',
              maxLength: v => v.length <= 30 || 'must not exceed 30 characters'
            }
          })}
        />
        {errors.displayName && <span className="form-error">{errors.displayName.message}</span>}
        <div className='opt-in-container'>
          <p className='text-fine-print'>By pressing 'create account' below, you agree to our <a href="/terms">terms and conditions</a>, our <a href="/privacy-policy">privacy policy</a> and our <a href="/eula">end user license agreement</a>.</p>
        </div>

        <UpdateButton text="Create Account" handlerFunc={handleSubmit(onSubmit)} updateInProgress={updateInProgress} />
      </form>
    </div>
  );
};

const SignUp: React.FC<SignUpProps> = ({ init, loggedIn }) => {
  const { register, handleSubmit, errors } = useForm<SignupFormData>({ mode: 'onBlur' });
  const [ phone, setPhone ] = React.useState('');
  const [ codeSent, setCodeSent ] = React.useState(false);
  const [ verificationToken, setVerificationToken ] = React.useState('');
  const history = useHistory();
  const query = useQuery();

  const redirect = () => {
    const redirect = query.get('postref');
    history.push(redirect ? `/event/${redirect}` : '/');
  };

  React.useEffect(() => {
    if (loggedIn) {
      redirect();
    }
  }, [ loggedIn ]);

  const onAuthSuccess = (username: string) => {
    init(); // initialize a session with the new account creds
  };

  const onSubmit = (data: SignupFormData) => {
    const phone = normalizePhoneNumber(data.phone);
    setPhone(phone);
    axios
      .post('/api/sms/send-verification-code', { phone }, getFetchParams())
      .catch((e) => console.log(e.toJSON()));

    setCodeSent(true);
  };

  const verificationInputVisible = codeSent && !verificationToken;

  const phoneForm = (
    <div className="signup-form">
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="text-small">Sign in or sign up to continue</div>
        <input
          type="text"
          disabled={codeSent}
          placeholder="phone #"
          name="phone"
          ref={register({
            required: 'phone number required',
            validate: v => validatePhoneNumber(v) || 'invalid phone number format'
          })}
        />
        {errors.phone && <span className="form-error">{errors.phone.message}</span>}
        <div>
          <div className="text-fine-print">Message & data rates may apply.</div>
          <UpdateButton text="Send Code" handlerFunc={handleSubmit(onSubmit)} />
        </div>
      </form>
    </div>
  );

  return (
    <div>
      {!codeSent && phoneForm}
      {verificationInputVisible && (
      <SubmitVerificationCode
        phone={phone}
        onSuccess={token => setVerificationToken(token)}
        onAuthSuccess={username => onAuthSuccess(username)}
      />)}
      {verificationToken && (
        <UsernameSelection
          verificationToken={verificationToken}
          onSuccess={username => onAuthSuccess(username)}
        />
      )}
      <div className="footer-container">
        <div>&copy; {new Date().getFullYear()} Impossible Effort Inc.</div>
      </div>

    </div>
  );
};

export default connector(SignUp);