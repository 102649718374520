import { v4 as uuidv4 } from 'uuid';
import Store from '@happenings/components/store';

export const getFetchParams = (token?: string) => {
  const base = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (token) {
    return {
      headers: {
        ...base,
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return { headers: base };
};

export function isUrl(s: string): boolean {
  // eslint-disable-next-line no-useless-escape
  const regexp =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  return regexp.test(s);
}

// helper to add http protocol to url string if absent
export const linkHelper = (url) =>
  !/^https?:\/\//i.test(url) ? `http://${url}` : url;

export const getToken = (state: Store) => state.session.currentUser?.token;

interface withId {
  id: number;
}

export const mapEntitiesById = (entities: withId[]): Record<number, withId> => {
  const mapEntity = (
    acc: Record<number, withId>,
    curr: withId
  ): Record<number, withId> => {
    if (curr.id in acc) throw new Error('duplicate keys in input');
    acc[curr.id] = curr;
    return acc;
  };
  return entities.reduce(mapEntity, {});
};

export const uid = () => uuidv4();

export const sanitizeDisplayName = (displayName: string): string => {
  // strip whitespace from beginning and end of string, preserving spaces inbetween
  const sanitized = displayName.replace(/^\s+|\s+$/g, '')
  // ensure all sequences of spaces are replaced with a single space
  .replace(/\s+/g, ' ');
  return sanitized;
};

export const shortenString = (s: string, len: number): string => {
  if (!s) return '';
  if (s.length <= len) return s;
  return s.slice(0, len) + '...';
}