import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';

import { formDataFromPost } from '@happenings/components/create';

import ImageHandler from './ImageHandler';
import UpdateButton from '../util/UpdateButton';
import LoadingIcon from '../common/LoadingIcon';
import DatePicker from './DatePicker';
import EventCheckboxForm from './EventCheckboxForm';
import { CreateForm } from '@happenings/components/create';

import { AGES_ALL } from '../../constants/ageRestrictions';

const INITIAL_FIELD_STATES: CreateForm = {
  title: '',
  location: '',
  description: '',
  isPrivate: false,
  isAccessible: false,
  guestsCanInvite: false,
  eventStart: null,
  eventEnd: null,
  ageRestriction: 'AGES_ALL',
  entityUrl: '',
};

const EditPostPage = (props) => {
  let formData = INITIAL_FIELD_STATES;
  const { posts, postId, newlyCreatedPostId } = props;
  const post = posts ? posts[postId] : undefined;

  useEffect(() => {
    formData = post ? formDataFromPost(post) : INITIAL_FIELD_STATES;
  }, [props.posts]);

  const defaultValues = {
    eventStart: formData.eventStart || null,
    eventEnd: formData.eventEnd || null,
    isPrivate: formData.isPrivate || false,
    guestsCanInvite: formData.guestsCanInvite || false,
    isAccessible: formData.isAccessible || false,
    ageRestriction: formData.ageRestriction || AGES_ALL,
  };

  const { register, handleSubmit, setValue, watch, errors } = useForm({ defaultValues });

  useEffect(() => {
    props.clearFormFields();
    if (!props.isNewEvent && !props.post) { props.initForm(postId); }
  }, [postId]);

  // custom registration of controlled form fields
  useEffect(() => {
    register({ name: 'eventStart' }, { validate: v => !!v || 'event date is required' });
    register({ name: 'eventEnd' });
    register({ name: 'isPrivate' });
    register({ name: 'guestsCanInvite' });
    register({ name: 'isAccessible' });
    register({ name: 'ageRestriction' });
  }, [register]);

  // get references to controlled form fields that have been registered
  const {
    eventStart, eventEnd, isPrivate, guestsCanInvite, isAccessible, ageRestriction,
  } = watch();

  // listen on formData changes (e.g. when initial post data fetch completes),
  // update form state accordingly
  useEffect(() => {
    setValue('title', formData.title);
    setValue('location', formData.location);
    setValue('description', formData.description);
    setValue('ageRestriction', formData.ageRestriction);
    setValue('isAccessible', formData.isAccessible);
    setValue('guestsCanInvite', formData.guestsCanInvite);
    setValue('isPrivate', formData.isPrivate);
    setValue('eventStart', formData.eventStart);
    setValue('eventEnd', formData.eventEnd);
  }, [props.posts]);

  const onSubmit = (data: CreateForm) => {
    console.log('submitting form data', data);
    if (props.isNewEvent) {
      props.createEvent(data);
    } else {
      props.updateEvent(postId, data);
    }
  };

  const { isNewEvent, imgLoaded, eventImageCleared } = props;

  const btnDisableCondition = (isNewEvent && !imgLoaded)
    || (!isNewEvent && eventImageCleared && !imgLoaded)
    ;

  const receivedNewlyCreatedPost = newlyCreatedPostId !== null;
  if (receivedNewlyCreatedPost) {
    return (
      <Redirect to={`/event/${newlyCreatedPostId}`} />
    )
  }

  const requiredMsg = <span className="form-error">required</span>;
  return props.loaded ? (
    <div className="create-container">
      {props.isNewEvent ? 'Create a new event' : 'Edit your event'}
      <div className="event-info-form">
        <form className="form">
          <input
            name="title"
            placeholder="Title"
            defaultValue={formData.title}
            ref={register({ required: 'required field' })}
          />
          { errors.title && requiredMsg }
          <input
            name="location"
            placeholder="Location"
            defaultValue={formData.location}
            ref={register()}
          />
          {/* n.b. controlled form field */}
          <DatePicker
            startDate={eventStart}
            endDate={eventEnd}
            onChange={(tab, d) => setValue(tab, d)}
          />
          { errors.date && requiredMsg }
          <textarea
            name="description"
            placeholder="Additional details"
            defaultValue={formData.description}
            ref={register()}
          />
          {/* n.b. wrapper component around controlled form checkbox fields */}
          <EventCheckboxForm
            formData={{ isPrivate, guestsCanInvite, isAccessible, ageRestriction }}
            handleChange={(fieldName, isSelected) => setValue(fieldName, isSelected)}
          />
          <ImageHandler
            showPreview={!(props.eventImageCleared || props.isNewEvent)}
            imgUrl={post ? post.publicUrl : ''}
          />
          <UpdateButton
            disabled={btnDisableCondition}
            text={props.isNewEvent ? 'Publish Event' : 'Save Changes'}
            handlerFunc={handleSubmit(onSubmit)}
          />
        </form>
      </div>
    </div>
  ) : <LoadingIcon />;
};

EditPostPage.propTypes = {
  loaded: PropTypes.bool,
  isNewEvent: PropTypes.bool.isRequired,
  imgLoaded: PropTypes.bool.isRequired,
  clearFormFields: PropTypes.func.isRequired,
  initForm: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,

  post: PropTypes.shape({
    publicUrl: PropTypes.string,
  }),

  eventImageCleared: PropTypes.bool.isRequired,

  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      event_id: PropTypes.string,
    }),
  }).isRequired,
};

EditPostPage.defaultProps = {
  loaded: false,
  post: null,
};

export default EditPostPage;
