import axios from 'axios';
import { put, takeLatest, select, call } from 'redux-saga/effects';

import { CreateForm, SignedUrls } from './index';
import { actionTypes as createActions } from './index';
import { ApiPayload } from '@happenings/components/common/types';
import { getToken } from '@happenings/components/session';
import {
  getPostData,
  PostBase,
  Post as PostType,
} from '@happenings/components/post';
import { getFetchParams, uid } from '@happenings/components/util';
import * as types from '@happenings/components/constants/actionTypes';
import { PostBodyFromForm } from './index';
import { validateFormData } from './validation';
import Store from '../store';

export const localImageSelector = (state: Store) => state.ui.form.localImage;
export const currUserSelector = (state: Store) => state.session.currentUser;

/**
 * get signed URLs to upload to and download from google cloud storage.
 */
export function* getSignedUrls(
  url: string,
  username: string,
  fileName: string
) {
  const token = yield select(getToken);
  const uri = `${url}/api/upload/get-signed-url`;
  const params = getFetchParams(token);
  yield put({ type: createActions.REQUEST_SIGNED_URL });
  const res = yield call(axios.post, uri, { username, fileName }, params);
  yield put({ type: createActions.RECEIVE_SIGNED_URL });
  return res.data;
}

export function* uploadEventImage(signedUploadUrl: string, localImage: File) {
  const params = {
    headers: { 'Content-Type': localImage.type },
  };
  yield put({ type: createActions.BEGIN_IMAGE_UPLOAD });
  const res = yield call(axios.put, signedUploadUrl, localImage, params);
  yield put({ type: createActions.IMAGE_UPLOAD_SUCCESS });
  return res.data;
}

export type ApiGeneratedFields = {
  publicUrl: string;
  thumbPublicUrl: string;
  storageUri: string;
  thumbStorageUri: string;
};

export type PersistablePost = PostBase & ApiGeneratedFields;

export function* persistEventData(url: string, payload: PersistablePost) {
  const token = yield select(getToken);
  const uri = `${url}/api/posts`;
  const params = getFetchParams(token);

  const res = yield call(axios.post, uri, payload, params);
  return res.data;
}

export type createPayload = ApiPayload & {
  formData: CreateForm;
};

/**
 * createEvent is the top-level entrypoint for all that needs to happen
 * when creating an event
 */
export function* createEvent({
  payload,
}: {
  type: string;
  payload: createPayload;
}) {
  try {
    yield put({ type: createActions.BEGIN_EVENT_UPLOAD });
    const { username } = yield select(currUserSelector);
    const localImage: File = yield select(localImageSelector);
    const err = yield call(validateFormData, localImage);
    if (err) {
      yield put({ type: types.RECEIVE_SESSION_ERRORS, error: err });
      return;
    }

    let postBody: PostType;
    // TODO: dont do this in a generator,
    // can be synchronous in view logic before saga is even called.
    try {
      postBody = yield call(PostBodyFromForm, payload.formData);
    } catch (err) {
      yield put({
        type: types.RECEIVE_SESSION_ERRORS,
        error: 'event fields invalid',
      });
      return;
    }
    const uploadName = `${uid()}.${localImage.type.split('/')[1]}`;

    // get signed urls to upload + later download event image
    const bucketUrls: SignedUrls = yield call(
      getSignedUrls,
      payload.url,
      username,
      uploadName,
    );

    // upload local image file
    const { signedUrl } = bucketUrls; // URL to upload image to
    yield call(uploadEventImage, signedUrl, localImage);

    const persistPayload: PersistablePost = {
      ...postBody,
      publicUrl: bucketUrls.publicUrl,
      thumbPublicUrl: bucketUrls.thumbPublicUrl,
      storageUri: bucketUrls.storageUri,
      thumbStorageUri: bucketUrls.thumbStorageUri,
    };

    // save event data to DB
    const persistRes = yield call(
      persistEventData,
      payload.url,
      persistPayload
    );

    yield put({
      type: createActions.COMPLETE_EVENT_UPLOAD,
      postId: persistRes.postId,
    });
  } catch (e) {
    yield put({
      type: types.RECEIVE_SESSION_ERRORS,
      error: e.message,
      statusCode: 500,
    });
  }
}

export type updatePayload = ApiPayload & {
  postId: number;
  formData: PostBase;
};

/**
 * update an event's info. Does not handle updating an events poster
 */
export function* updateEvent({ payload }: { type: string; payload: updatePayload }) {
  try {
    const postBody = payload.postData;
    const url = `${payload.url}/api/posts/${payload.postId}`;
    const token = yield select(getToken);
    const params = yield getFetchParams(token);
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    yield call(axios.put, url, postBody, params);
    yield put(getPostData(payload.postId));
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
  } catch (e) {
    yield put({
      type: types.RECEIVE_SESSION_ERRORS,
      error: 'failed to update event data',
    });
  }
}

export default function* main() {
  yield takeLatest(createActions.CREATE_EVENT, createEvent);
  yield takeLatest(createActions.UPDATE_EVENT, updateEvent);
}
