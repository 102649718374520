export const ADD_COMMENT = 'ADD_COMMENT';
export const GET_COMMENTS = 'GET_COMMENTS';
export const RECEIVE_COMMENTS = 'RECEIVE_COMMENTS';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';

export type Comment = {
  id: number;
  text: string;
  user_id: number;
  created: string;
  username: string;
  display_name: string;
};

interface AddCommentAction {
  type: typeof ADD_COMMENT;
  payload: { postId: number; userId: number; text: string };
}

interface ReceiveCommentAction {
  type: typeof RECEIVE_COMMENTS;
  payload: { postId: number; comments: Comment[] };
}

interface DeleteCommentAction {
  type: typeof DELETE_COMMENT;
  payload: { postId: number; commentId: number };
}

interface ClearCommentAction {
  type: typeof CLEAR_COMMENTS;
  payload: { postId: number };
}

interface GetCommentAction {
  type: typeof GET_COMMENTS;
  payload: { postId: number };
}

export type CommentActionTypes =
  | AddCommentAction
  | ReceiveCommentAction
  | DeleteCommentAction
  | ClearCommentAction
  | GetCommentAction;
