import { TIMELINE_GROUPBY_KEYS, TIMELINE_DISPLAY_STRINGS } from './constants';
import { GranularityType } from '@happenings/components/profile';
import { FormDate } from '@happenings/components/create';
import { format, parse, isThisYear } from 'date-fns';

// the ISO 8601 supported string format we use.
export const DATE_FORMAT_STRING = 'YYYY-MM-DD HH:mm';

export const dateFromTimestamp = (timestamp: string): Date => {
  return parse(timestamp);
};

export const timestampFromDate = (date: Date): string => {
  return format(date, DATE_FORMAT_STRING);
};

const padZero = (baseInt: number) => {
  return baseInt < 10 ? `0${baseInt}` : `${baseInt}`;
};

export const isInThePast = (timestamp: string): boolean => {
  return parse(timestamp).getTime() < new Date().getTime();
}

/**
 * formDataToDate reconciles all the temporal form info into a single Date object.
 * We store 'hour', 'minute', and Am/Pm info separately from the calendar Date
 * in the store to avoid excessive Date manipulation during state updates.
 */
export const formDataToDate = (formDate: FormDate): Date => {
  const { date: date, hour: hourStr, minute: minuteStr, amOrPm } = formDate;
  const dateCopy = new Date(date.getTime());
  const minutes = parseInt(minuteStr); // '15' -> 15
  const hour = parseInt(hourStr); // '02' -> 2
  const hour24 = amOrPm === 'PM' ? hour + 12 : hour;
  dateCopy.setHours(hour24, minutes, 0, 0);
  return dateCopy;
};

export const formFieldsFromDate = (date: Date): FormDate => {
  const hour = date.getHours();
  const hour12 = hour > 12 ? hour % 12 : hour;
  const hour12Str = padZero(hour12);
  const minuteStr = extractQuarterHour(date);
  const amOrPm = hour >= 12 ? 'PM' : 'AM';
  return { date: date, hour: hour12Str, minute: minuteStr, amOrPm };
};

/**
 * Group a collection of posts by a calendar granularity
 */

interface withEventTimestamp {
  eventTimestamp: string
}

export const groupByGranularity = (
  posts: withEventTimestamp[],
  granularity: GranularityType
): Record<string, withEventTimestamp[]> => {
  const grouped = {};
  const groupByFormat = TIMELINE_GROUPBY_KEYS[granularity];

  Object.keys(posts).forEach((postId) => {
    const postData = posts[postId];
    const groupBy = format(parse(postData.eventTimestamp), groupByFormat);
    if (Object.keys(grouped).includes(groupBy)) {
      grouped[groupBy].push(postData);
    } else {
      grouped[groupBy] = [postData];
    }
  });

  return grouped;
};

export const extractQuarterHour = (date: Date): string => {
  const minutes = date.getMinutes();
  const validMinutes = ['00', '15', '30', '45'];

  const minutesStr = padZero(minutes);

  if (!validMinutes.includes(minutesStr)) {
    console.error('Date is not exactly on a quarter of the hour', minutesStr);
  }

  return minutesStr;
};

export const formatSection = (groupKey: string, granularity: string): string => {
  const formatKey = TIMELINE_DISPLAY_STRINGS[granularity];
  return format(parse(groupKey), formatKey);
};

export const conciseTimeAgo = (isoTimestamp: string) => {
  const timestamp = parse(isoTimestamp).getTime();
  const now = new Date().getTime();
  const deltaSeconds = Math.floor((now - timestamp) / 1000);

  const SECONDS_IN_A_YEAR = 365 * 86400;
  const SECONDS_IN_A_MONTH = 30 * 86400;
  const SECONDS_IN_A_WEEK = 7 * 86400;
  const SECONDS_IN_A_DAY = 86400;
  const SECONDS_IN_AN_HOUR = 3600;
  const SECONDS_IN_A_MINUTE = 60;

  if (deltaSeconds >= SECONDS_IN_A_YEAR) {
    const years = Math.floor(deltaSeconds / SECONDS_IN_A_YEAR);
    return `${years}y`;
  } else if (deltaSeconds >= SECONDS_IN_A_MONTH) {
    const months = Math.floor(deltaSeconds / SECONDS_IN_A_MONTH);
    return `${months}mo`;
  } else if (deltaSeconds >= SECONDS_IN_A_WEEK) {
    const weeks = Math.floor(deltaSeconds / SECONDS_IN_A_WEEK);
    return `${weeks}w`;
  } else if (deltaSeconds >= SECONDS_IN_A_DAY) {
    const days = Math.floor(deltaSeconds / SECONDS_IN_A_DAY);
    return `${days}d`;
  } else if (deltaSeconds >= SECONDS_IN_AN_HOUR) {
    const hours = Math.floor(deltaSeconds / SECONDS_IN_AN_HOUR);
    return `${hours}h`;
  } else if (deltaSeconds >= SECONDS_IN_A_MINUTE) {
    const minutes = Math.floor(deltaSeconds / SECONDS_IN_A_MINUTE);
    return `${minutes}m`;
  } else {
    return `${deltaSeconds}s`;
  }
}

// human readable from ISO timestamp
export const humanReadableTime = (isoTimestamp: string) => {
  const date = parse(isoTimestamp);
  const formatString = isThisYear(date) ? 'MMMM Do' : 'MMMM Do, YYYY';
  return format(date, formatString);
};
