import React, { useState } from 'react';
import { format, isSameDay, startOfDay, addHours } from 'date-fns';
import DayPicker from 'react-day-picker';
import classNames from 'classnames';
import { formFieldsFromDate } from '@happenings/components/util/dates';
import KeySelector from '../util/KeySelector';

type Props = {
  date: Date | null;
  onDateChange: (date: Date) => void;
};

const DatePicker: React.FC<Props> = ({ date, onDateChange }) => {
  const defaultDate = date || startOfDay(new Date());
  const { hour, minute, amOrPm } = defaultDate ? formFieldsFromDate(defaultDate)
    : { hour: '12', minute: '00', amOrPm: 'AM' };

  const handleTimeChange = (date, hour, minute, amOrPm) => {
    const newDate = new Date(date);
    let hourInt = parseInt(hour, 10);
    if (amOrPm === 'PM' && hourInt !== 12) {
      hourInt += 12;
    } else if (amOrPm === 'AM' && hourInt === 12) {
      hourInt = 0;
    }
    newDate.setHours(hourInt);
    newDate.setMinutes(parseInt(minute, 10));
    onDateChange(newDate);
  };

  const picker = (
    <div>
      <DayPicker
        onDayClick={onDateChange}
        initialMonth={defaultDate}
        selectedDays={date}
        firstDayOfWeek={1} // start week on monday
      />
      <div className="time-picker-container">
        <div className="hour-selection">
          HH
          <KeySelector
            containerClass="hour-selector"
            keyList={[ '12', '01', '02', '03' ]}
            btnClass="unselected-btn"
            selection={hour}
            onChange={val => handleTimeChange(date, val, minute, amOrPm)}
          />
          <KeySelector
            containerClass="hour-selector"
            keyList={[ '04', '05', '06', '07' ]}
            btnClass="unselected-btn"
            selection={hour}
            onChange={val => handleTimeChange(date, val, minute, amOrPm)}
          />
          <KeySelector
            containerClass="hour-selector"
            keyList={[ '08', '09', '10', '11' ]}
            btnClass="unselected-btn"
            selection={hour}
            onChange={val => handleTimeChange(date, val, minute, amOrPm)}
          />
        </div>
        <div className="minute-selection">
          MM
          <KeySelector
            containerClass="hour-selector"
            keyList={[ '00', '15', '30', '45' ]}
            btnClass="unselected-btn"
            selection={minute}
            onChange={val => handleTimeChange(date, hour, val, amOrPm)}
          />
        </div>
        <div className="am-pm-selection">
          <KeySelector
            containerClass="hour-selector"
            keyList={[ 'AM', 'PM' ]}
            btnClass="unselected-btn"
            selection={amOrPm}
            onChange={val => handleTimeChange(date, hour, minute, val)}
          />
        </div>
      </div>
    </div>
  );

  return picker;

};

type DateTab = 'eventStart' | 'eventEnd';
type DatePickerTabsProps = {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (tab: DateTab, date: Date) => void;
};

const formatDateRange = (startDate: Date, endDate: Date): string => {
  const start = format(startDate, 'ddd MMM DD [at] h:mma');
  const end = format(endDate, 'h:mma');
  if (isSameDay(startDate, endDate)) {
    return `${format(startDate, 'ddd MMM DD')} ${format(startDate, 'h:mma')} - ${end}`;
  }
  return `${start} - ${format(endDate, 'ddd MMM DD [at] h:mma')}`;
};

const DatePickerTabs: React.FC<DatePickerTabsProps> = ({ startDate, endDate, onChange }) => {
  const [ pickerOpen, setPickerOpen ] = useState(false);
  const [ selectedTab, setSelectedTab ] = useState<DateTab>('eventStart');

  const picker = (
    <>
      <div className="tabs">
        <div
          className={classNames('tab', { 'active': selectedTab === 'eventStart' })}
          onClick={() => setSelectedTab('eventStart')}
        >
          start
        </div>
        <div
          className={classNames('tab', { 'active': selectedTab === 'eventEnd' })}
          onClick={() => {
            setSelectedTab('eventEnd');
            if (!endDate) {
              const defaultEndDate = addHours(startDate, 4);
              onChange('eventEnd', defaultEndDate);
            }
          }}
        >
          end
          {endDate ? (
            <span className="subtext" onClick={() => onChange('eventEnd', null)}>{' [x]'}</span>
          ) : (' (+)')
          }
        </div>
      </div>
      {selectedTab === 'eventEnd' &&
        <DatePicker date={endDate} onDateChange={(d: Date) => onChange(selectedTab, d)} />
      }
      {selectedTab === 'eventStart' &&
        <DatePicker date={startDate} onDateChange={(d: Date) => onChange(selectedTab, d)} />
      }
    </>
  );
  return (
    <div className="date-picker">
      <div
        role="button"
        className={classNames('date-field margin-bottom', { 'placeholder': !startDate })}
        onClick={() => setPickerOpen(!pickerOpen)}
      >
        {(startDate && endDate)
          ? formatDateRange(startDate, endDate)
          : (startDate ? format(startDate, 'EEE MMM dd h:mma') : 'Date')
        }
      </div>
      {pickerOpen && picker}
    </div>
  );
};

export default DatePickerTabs;
