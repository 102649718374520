/* eslint-disable func-names */
import axios from 'axios';
import { put, takeLatest, take, call, select, cancelled, race } from 'redux-saga/effects';

import * as types from '@happenings/components/constants/actionTypes';
import { getToken } from '@happenings/components/session';
import { getFetchParams } from '@happenings/components/util';
import Store from '@happenings/components/store';
import { ApiAction, ApiPayload } from '@happenings/components/common/types';
import { PageState } from '@happenings/components/pagination';
import { FeedActionTypes } from '.'

interface feedAction extends ApiAction {
  payload: ApiPayload & { feedName: string };
}

export const getPagination = (state: Store): PageState => state.pagination.feed;

export function* getFeedByName({ payload }: feedAction) {
  try {
    const { lastEvaluatedKey, hasMore } = yield select(getPagination);
    if (hasMore) {
      const token = yield select(getToken);
      const feedUri: string = payload.feedName;

      const pageParam = lastEvaluatedKey ? `?startAfter=${lastEvaluatedKey}` : '';
      const url = `${payload.url}/api/feed/${feedUri}${pageParam}`;
      const params = getFetchParams(token);

      yield put({ type: types.BEGIN_PAGE_FETCH, feedName: payload.feedName });
      const res = yield call(axios.get, url, params);
      yield put({ type: types.RECEIVE_FEED_PAGE, feedName: payload.feedName, posts: res.data });
    }
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: e?.response?.status || 400 });
  } finally {
    if (yield cancelled()) {
      yield put({ type: types.CANCEL_PAGE_FETCH });
    }
  }
}

export default function* main() {
  yield takeLatest(FeedActionTypes.GET_FEED, function* (action: feedAction) {
    // if route changes before getFeedByName
    // finishes fetching, cancel getFeedByName generator.
    yield race({
      task: call(getFeedByName, action),
      cancel: take(types.ROUTE_CHANGE),
    });
  });
}
