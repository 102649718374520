import { Action } from 'redux';
import {
  dateFromTimestamp,
  timestampFromDate,
} from '@happenings/components/util/dates';

import { AgeRestriction, PostBase } from '@happenings/components/post';

import { FetchAction } from '@happenings/components/common/types';

export type FormDate = {
  date: Date;
  hour: string;
  minute: string;
  amOrPm: string;
};

// web-specific TODO move somewhere else
export type CreateForm = {
  title: string;
  description: string;
  entityUrl: string;
  location: string;
  isPrivate: boolean;
  isAccessible: boolean;
  guestsCanInvite: boolean;
  ageRestriction: AgeRestriction;
  eventStart: Date | null;
  eventEnd: Date | null;
};

export type SignedUrls = {
  signedUrl: string; // for the one time PUT upload
  publicUrl: string;
  thumbPublicUrl: string;
  storageUri: string;
  thumbStorageUri: string;
};

export const actionTypes = {
  CREATE_EVENT: 'CREATE_EVENT',
  UPDATE_EVENT: 'UPDATE_EVENT',
  BEGIN_EVENT_UPLOAD: 'BEGIN_EVENT_UPLOAD',
  COMPLETE_EVENT_UPLOAD: 'COMPLETE_EVENT_UPLOAD',
  REQUEST_SIGNED_URL: 'REQUEST_SIGNED_URL',
  RECEIVE_SIGNED_URL: 'RECEIVE_SIGNED_URL',
  BEGIN_IMAGE_UPLOAD: 'BEGIN_IMAGE_UPLOAD',
  IMAGE_UPLOAD_SUCCESS: 'IMAGE_UPLOAD_SUCCESS',
  IMAGE_UPLOAD_FAILURE: 'IMAGE_UPLOAD_FAILURE',
  CLEAR_FORM_FIELDS: 'CLEAR_FORM_FIELDS',
  LOAD_LOCAL_IMAGE: 'LOAD_LOCAL_IMAGE',
  CLEAR_LOCAL_IMAGE: 'CLEAR_LOCAL_IMAGE',
};

export const beginEventUpload = (): Action => ({
  type: actionTypes.BEGIN_EVENT_UPLOAD,
});

export const eventUploadCompleted = (): Action => ({
  type: actionTypes.COMPLETE_EVENT_UPLOAD,
});

export const createEvent = (formData: CreateForm): FetchAction => ({
  type: actionTypes.CREATE_EVENT,
  payload: { formData },
});

export const updateEvent = (
  postId: number,
  formData: CreateForm
): FetchAction => ({
  type: actionTypes.UPDATE_EVENT,
  payload: {
    postId,
    postData: PostBodyFromForm(formData),
  },
});

export const loadLocalImage = (imgFile: File) => ({
  type: actionTypes.LOAD_LOCAL_IMAGE,
  eventImage: imgFile,
});

export const clearLocalImage = (): Action => ({
  type: actionTypes.CLEAR_LOCAL_IMAGE,
});

export const clearFormFields = (): Action => ({
  type: actionTypes.CLEAR_FORM_FIELDS,
});

/**
 * pass safe default values,
 * initialize Date from timestamp
 */
export const formDataFromPost = (post: PostBase): CreateForm => {
  const date = dateFromTimestamp(post.eventTimestamp);
  const dateEnd = post.endTimestamp
    ? dateFromTimestamp(post.endTimestamp)
    : null;

  return {
    title: post?.title,
    description: post?.description,
    entityUrl: post?.entityUrl,
    location: post?.location,
    eventStart: date,
    eventEnd: dateEnd,
    isPrivate: post?.isPrivate || false,
    isAccessible: post?.isAccessible || false,
    guestsCanInvite: post?.guestsCanInvite || false,
    ageRestriction: post?.ageRestriction || 'AGES_ALL',
  };
};

export const PostBodyFromForm = (formData: CreateForm): PostBase => {
  const startTimestamp = formData.eventStart ? timestampFromDate(formData.eventStart) : null;
  if (!startTimestamp) {
    throw new Error('event start date is required');
  }
  const endTimestamp = formData.eventEnd
    ? timestampFromDate(formData.eventEnd)
    : null;

  return {
    title: formData.title,
    description: formData.description,
    entityUrl: formData.entityUrl,
    location: formData.location,
    isPrivate: formData.isPrivate,
    isAccessible: formData.isAccessible,
    guestsCanInvite: formData.guestsCanInvite,
    ageRestriction: formData.ageRestriction,
    eventTimestamp: startTimestamp,
    endTimestamp: endTimestamp,
  };
};

export type CreateFormState = {
  localImage: File | null;
  eventImageCleared: boolean;
  newlyCreatedPostId: number | null;
};

const INITIAL_FORM_STATE: CreateFormState = {
  localImage: null,
  eventImageCleared: false,
  newlyCreatedPostId: null,
};

const formReducer = (state = INITIAL_FORM_STATE, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_FORM_FIELDS:
      return { ...state, ...INITIAL_FORM_STATE };
    // drop the newly created PostID here so the view layer
    // can redirect to the new post data
    case actionTypes.COMPLETE_EVENT_UPLOAD:
      return {
        ...state,
        ...INITIAL_FORM_STATE,
        newlyCreatedPostId: action.postId,
      };
    case actionTypes.LOAD_LOCAL_IMAGE:
      return { ...state, localImage: action.eventImage };
    case actionTypes.CLEAR_LOCAL_IMAGE:
      return { ...state, localImage: null, eventImageCleared: true };
    default:
      return state;
  }
};

export default formReducer;
