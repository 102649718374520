import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { groupByGranularity, formatSection } from '@happenings/components/util/dates';
import {
  TIMELINE_GRANULARITIES,
  TIMELINE_FEED_TYPES,
} from '../../constants';

import TimelineFeedSelector from './TimelineFeedSelector';
import GranularitySelector from './GranularitySelector';
import LoadingIcon from '../common/LoadingIcon';
import ScrollLoader from '../common/ScrollLoader';

/**
 * Publically viewable page, don't need to be signed in
 */
class ProfilePosts extends React.Component {
  DEFAULT_STATE = {
    feedType: TIMELINE_FEED_TYPES.ATTENDING,
    granularity: TIMELINE_GRANULARITIES.MONTH,
  };

  state = { ...this.DEFAULT_STATE };

  componentDidMount() {
    this.pullFeed(this.state.feedType);
  }

  pullFeed = (feedType) => {
    this.setState({ feedType }, () =>
      this.props.getTimelinePosts(this.state.feedType)
    );
  };

  onFeedChange = (feedType) => {
    this.props.changeFeed(); // clears out the post data in redux
    this.pullFeed(feedType);
  };

  render() {
    const { postsByFeed, loading } = this.props;
    const posts = postsByFeed[this.state.feedType] || [];
    const groupedPosts = groupByGranularity(posts, this.state.granularity);
    const sections = Object.keys(groupedPosts).sort().reverse();

    const masonrySections = sections.map((sectionGroupKey) => {
      const sectionPosts = groupedPosts[sectionGroupKey];
      const sectionTitle = formatSection(
        sectionGroupKey,
        this.state.granularity
      );

      const masonryBodyContent = sectionPosts.map((post) => (
        <Link
          key={post.id}
          className="timeline-post-thumb"
          to={`/event/${post.id}`}
        >
          <img src={post.thumbPublicUrl} alt={post.thumbPublicUrl} />
        </Link>
      ));

      return (
        <div key={sectionGroupKey}>
          <div className="timeline-section-header">{sectionTitle}</div>
          <div className="timeline-section-body">{masonryBodyContent}</div>
        </div>
      );
    });

    return (
      <div className="timeline-container">
        <TimelineFeedSelector
          selection={this.state.feedType}
          onChange={this.onFeedChange}
        />
        <GranularitySelector
          selection={this.state.granularity}
          onChange={(granularity) => this.setState({ granularity })}
        />
        {masonrySections}
        <ScrollLoader
          onEnter={() => {
            this.pullFeed(this.state.feedType);
          }}
          isLoading={loading}
        />
        {loading && <LoadingIcon />}
      </div>
    );
  }
}

ProfilePosts.propTypes = {
  postsByFeed: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  getTimelinePosts: PropTypes.func.isRequired,
  changeFeed: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ProfilePosts;
